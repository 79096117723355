import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PriceConversionPipe implements PipeTransform {

  transform(value: unknown, type: 'local' | 'fixed', digits?: any): unknown {

    switch (type) {
      case 'fixed': return Number(value).toFixed(digits);
      case 'local': return Number(value).toLocaleString();
      default: return value
    }
  }

}
