import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weight'
})
export class WeightConversionPipe implements PipeTransform {

  transform(value: unknown, unit: 'g' | 'kg'): unknown {
    switch (unit) {
      case 'g': return `${Number(value) * 1000} g`
      case 'kg': return `${Number(value) / 1000} g`
      default: return ''
    }
  }

}
