import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { FileSizePipe } from './filesize.pipe';
import { SafePipe } from './safe.pipe';
import { WeightConversionPipe } from './weight-conversion.pipe';
import { PriceConversionPipe } from './price-conversion.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  FileSizePipe,
  SafePipe,
  WeightConversionPipe,
  PriceConversionPipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule { }