export const environment = {
  production: false,

  EmpapiURL: 'https://api.theprintapp.com/api/V1/EP/', //staging
  apiURL: 'https://api.theprintapp.com/api/V1/AP/', //staging 
  socketUrl: 'https://chat.theprintapp.com/', //staging  


  // EmpapiURL: 'https://testapi.theprintapp.com/api/V1/EP/', //Development 
  // apiURL: 'https://testapi.theprintapp.com/api/V1/AP/', //Development 
  // socketUrl: 'https://testchat.theprintapp.com/', //Development 


  pickup_postcode: '625014',
  firebaseConfig: {
    apiKey: "AIzaSyDbRrNpzEqzXABno5LTKN4Blu9fbYUAMto",
    authDomain: "printapp-production.firebaseapp.com",
    projectId: "printapp-production",
    storageBucket: "printapp-production.firebasestorage.app",
    messagingSenderId: "955574849534",
    appId: "1:955574849534:web:8c3dce05bbd190a8bcbaaf",
    measurementId: "G-T20HT8EGQW"
  }
  ,
};

